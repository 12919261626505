import React from "react"
import { Link } from "gatsby"
import Layout from "../containers/layout"
import SEO from "../components/seo"
import { useAppContext } from "../context"

const IndexPage = () => {
  const app = useAppContext()

  return (
    <Layout>
      <SEO title="Home" />
      <div className="jumbotron">
        <div className="container">
          {!app.user && (
            <>
              <h1 className="jumbotron-heading">Hello</h1>
              <p className="lead">Please login to continue.</p>
              <hr className="my-4" />
              <a className="btn btn-primary btn-lg" href="/api/auth/login">
                Login
              </a>
            </>
          )}
          {app.user && (
            <>
              {/* <h1 className="jumbotron-heading">Welcome</h1> */}
              <h1 className="jumbotron-heading">What Is This?</h1>
              <p>
                Each week we receive many submissions from Instagram users
                tagging their posts with the{" "}
                <a
                  href="https://www.instagram.com/explore/tags/buckuback/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  #buckuback
                </a>{" "}
                hashtag in a effort to get their work posted on the Bucky Wall.
                Each week this site will create a new poll so that the Buck
                Massive can vote for their favorite submissions for that week.
                The results are then uploaded to a story on{" "}
                <a
                  href="https://www.instagram.com/buck_design/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buck's Instagram
                </a>
                .
              </p>
              <hr className="my-4" />
              <p className="lead">
                <Link className="btn btn-primary btn-lg mr-2" to="/poll">
                  Vote
                </Link>
                <Link className="btn btn-secondary btn-lg" to="/results">
                  View Results
                </Link>
              </p>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
